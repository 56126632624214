import React,{useState, useEffect} from 'react'
// import axios from "axios"
import '../pages/css.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ExperienceBuilder from '../components/Experienceb';
import Exposure from '../components/Exposure';
import Education from '../components/Education';
import Header from '../components/Header';
import Criticalskills from '../components/Criticalskills';
import axios from 'axios';
import ConfmOverlay from '../components/ConfmOverlay';

let submit = 0;
let count = 0;

const Landing = () => {
  useEffect(() =>{
    if(submit > 0){
      handleFinalSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  const [confirmoverLay, setConfirmoverLay] = useState(false)

  const API = 'https://iasg-staff-management.herokuapp.com'

  let [fire, setFire] = useState(0)

  const [objcsk, setObjcsk] = useState({
    namesfield: "",
    rolefield: "",
    reporttofield: "",
    functionfield: "",
    ratingfield:"",
    imageurl: "",
  })

  const [experience, setExperience] = useState({
    Has: [],
    Needs: []
  })

  const [experienceBuild, setExperienceBuild] = useState([])

  const [exposures, setExposures] = useState([])

  const [educations, setEducations] = useState([])
  
  const handleFinalSubmit = async () =>{
      const obj = {
        Name: objcsk.namesfield,
        CurrentRole: objcsk.rolefield,
        ReportingLine: objcsk.reporttofield,
        Function: objcsk.functionfield,
        ImagePath: "Benita",
        ImageURL: objcsk.imageurl,
        Experience: {
          Has: experience.Has,
          Needs: experience.Needs
        },
        ExperienceBuilders: [...experienceBuild],
        Exposures: [...exposures],
        Education: [...educations]
      }
      submit = 0;

      await axios.post(`${API}/register`, obj).then((response) => {
        console.log(`My Response: ${JSON.stringify(response.data)}`);
        if(response.data.code === 200){
          console.log(`My Response: ${JSON.stringify(response.data)}`);
          setConfirmoverLay(true)
      }});
  }

  const handleSubmit = (e) =>{
    e.preventDefault()
    setFire(fire++)
  }

  const handleHead = (incoming) =>{
    console.log(incoming)
    setObjcsk({namesfield: incoming.names, rolefield: incoming.currentRole, reporttofield: incoming.reportingLine, functionfield: incoming.function, imageurl: incoming.ImgUrl})
    // submit++
    console.log(submit)
  }

  const experienceData = (incoming) =>{
    console.log(incoming)
    setExperience({Needs: [...(incoming.need)], Has: [...(incoming.has)]})
    submit++
    // console.log(submit)
  }

  const exposureData = (incoming) =>{
    setExposures(exposures => [...exposures, incoming])
  }

  const experienceBuildData = (incoming) =>{
    count++
    setExperienceBuild(experienceBuild => [...experienceBuild, incoming])
    submit++
    // console.log(`Obalolu: ${count}`)
    // console.log(`my submit: ${submit}`)
  }

  const educationData = (incoming) =>{
    setEducations(educations => [...educations, incoming])
  }

  //// ****** CLOSE All Error/Confirmation Overlay ****** ///
  const handleClose = () =>{
    setConfirmoverLay(false)
    clearAllfields()
    window.location.reload(false);
  }

  const clearAllfields = () =>{
    setExperienceBuild([])
    setEducations([])
    setExposures([])
    setExperience({Has: [], Needs: []})
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className='landContain'>
        {confirmoverLay && <ConfmOverlay closeOverlay={handleClose} message={"Record Saved Successfully"} icon="1"/>}
        <div className='left-top-container'>
          <Header fired={fire} headerData={handleHead}/>
          <Criticalskills fired={fire} experienceData={experienceData}/>
        </div>
        <div className='right-bottom-container'>
          <ExperienceBuilder fired={fire} experbuildData={experienceBuildData} heading="Experience Builders" need="Critical Experience" action="Action" when="When" status="Status"/>
          <Exposure fired={fire} exposureData={exposureData} heading="Exposures" need="Need" action="Action" when="When" status="Status"/>
          <Education educationData={educationData} fired={fire} heading="Education" need="Need" action="Action:Learning Program and Provider" when="When" status="Status"/>
          <input type='submit' id='submit'/>
        </div>
      </form>
    </div>
  )
}

export default Landing