import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt , FaPlus} from "react-icons/fa";
import '../pages/css.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { MdDeleteOutline } from "react-icons/md";

let i = 0;
let clicked = 0;
let rowFilled = false;

// let counter = 0;
const Education = ({fired, educationData, heading, need, action, when, status}) => {
    const [rowcount, setRowcount] = useState(1);
    const [experbuild, setExperbuild] = useState({
        Need: '',
        Program: '',
        When: '',
        Status: ''
    }   )

    const [experbuildList, setExperbuildList] = useState([])
    const [currDate, setCurrDate] = useState(new Date().toLocaleDateString('fr-FR'))

    useEffect(() => {
        if(clicked > 0){
            handleAltSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fired])

    useEffect(() => {
        if(clicked > 0){
            handleSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i])

    const handleSubmit = () =>{
        educationData(experbuild)
        return;
    }

    const handleAltSubmit = () =>{
        educationData(experbuild)
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setExperbuild({ ...experbuild, [name]: value });
    }

    const setSelect = (date) =>{
        console.log(date.toLocaleDateString('fr-FR'))
        setCurrDate(date.toLocaleDateString('fr-FR'))
        const value = new Date(date).toLocaleDateString('fr-FR')
        setExperbuild({ ...experbuild, When: value });
        clicked = 1;
    }

    const Clear = () =>{
        setExperbuild({CriticalExperience: '', Status: '', Action: '', When: ''})
    }

    const finalCompute = () =>{
        setExperbuildList([...experbuildList, experbuild])
        Clear() 
    }

    const handleClick = () =>{
        if(rowFilled === true){
            handleSubmit()
            setExperbuildList(experbuildList => [...experbuildList, experbuild])
            rowFilled = false
            setRowcount(rowcount + 1)
            finalCompute()
        } else if(experbuild.CriticalExperience !== '' && experbuild.Status !== '' && experbuild.Action !== '' && experbuild.When !== ''){
            handleSubmit()
            setExperbuildList(experbuildList => [...experbuildList, experbuild])
            rowFilled = true 
            setRowcount(rowcount + 1)
        }
    }

    const handleReduceClick = () =>{
        if(rowcount > 1){
            setRowcount(rowcount - 1)
        }
    }

  return (
        <div className="tableContain">
            <div className='tabletitle edu'>
                <span >
                    <FaPlus id="addIcon" onClick={handleClick}/>
                    <MdDeleteOutline id='remove' onClick={handleReduceClick }/>
                </span>
                <span id="titleicons">{heading}</span>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>{need}</th>
                        <th>{action}</th>
                        <th>{when}</th>
                        <th>{status}</th>
                    </tr>
                </thead>
                <tbody>
                {[...Array(rowcount)].map((index) => {
                    return(
                    <tr className="rows">
                        <td><textarea name='Need' onChange={handleChange} type="text" id="needsfield" placeholder='Insert Need'/></td>
                        <td><input name='Program' onChange={handleChange} id="needsfield" placeholder='Action'/></td>
                        <td><div id='subdate-contain'>
                            <DatePicker name='When' value={currDate} onSelect={setSelect} id="datepicker"/>
                            <FaRegCalendarAlt id='calender-icon'/>
                        </div></td>
                        <td><input name='Status' onChange={handleChange} placeholder='Status' id="needsfield"/></td>
                    </tr> 
                    );
                })}
                </tbody>
            </table>
        </div>
  )
}

export default Education