import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import { getFirestore, serverTimestamp } from "firebase/firestore";

// Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCBJKSNf1dy3IMqKntfKfXrtUrCqVMDzqI",
    authDomain: "iasg-application.firebaseapp.com",
    projectId: "iasg-application",
    storageBucket: "iasg-application.appspot.com",
    messagingSenderId: "314170330088",
    appId: "1:314170330088:web:a6f9a43a76ea230853325d",
    measurementId: "G-Y6P797EEV3"
  };
  
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  
  const projectStorage = getStorage(firebaseApp);
  const projectFirestore = getFirestore(firebaseApp)
  const timestamp = serverTimestamp();

  export {projectStorage,projectFirestore, timestamp};