import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Landing from './pages/landing';

function App() {
  return (
    <Router>
      <div className="AppContainer">
          <Routes>
            <Route exact path="/" element={<Landing />} /> 
            <Route exact path="/admin/upload" element={<Landing/>} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;
