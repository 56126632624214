import React from 'react'
import './style.css'
import { RiCloseCircleFill } from "react-icons/ri";
import { FaRegThumbsUp } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { GiSandsOfTime} from "react-icons/gi";
// import ErrorIcon from '../assets/error.svg';
import { Link } from 'react-router-dom';

const ConfmOverlay = ({closeOverlay, message, icon, isTimeout}) => {
    // const [close, setClose] = useState(false)
    const Iconobj = {
      1 : <FaRegThumbsUp id='confIcon'/>,
      2 : <TiCancel id='errorIcon'/>,
      3 : <GiSandsOfTime id='errorIcon'/>,
      4 : <div id='serverError'/>
    }
    const handleClose = () =>{
        // setClose(!close)
        closeOverlay(true)
    }

    const handleClick = () =>{

    }

  return (
    <div className='errorContainer'>
        <div className='errorSubContain'>
            <div className='ClsiconContain'><RiCloseCircleFill onClick={handleClose} id='closeIcon'/></div>
            <div className='iconContainer'>{Iconobj[icon]}</div>
            <span id="errorInfo">{message}</span>

            {isTimeout && <button id='bnw-logout-button' onClick={handleClick}><Link to="/" className='bnw-logout'>Log In</Link></button>}
        </div>
    </div>
  )
}

export default ConfmOverlay
