import React, {useEffect, useState} from 'react'
import '../pages/css.css'

const Criticalskills = ({fired, experienceData}) => {
    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fired])
    
    const [has_critknw, setHas_critknw] = useState({
        has_critknw_1: "",
        has_critknw_2: "",
        has_critknw_3: "",
    })

    const [need_critknw, setNeed_critknw] = useState({
        need_critknw_1: "",
        need_critknw_2: "",
        need_critknw_3: "",
    })

    const handleSubmit = () =>{
        const hasdata = Object.values(has_critknw)
        const needdata = Object.values(need_critknw)
        experienceData({need:needdata, has:hasdata})
    }

    const handleNeedChange = (event) =>{
        const {name, value} = event.target;
        setNeed_critknw({ ...need_critknw, [name]: value });
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setHas_critknw({ ...has_critknw, [name]: value });
    }

  return (
    <div className='skills-container'>
        <div className='tabletitl'>
            Critical Skills & Experiences
        </div>

        <div className='has-container'>
            <div className='has-title'>Has</div>
            <div>
                <span>1. <input name="has_critknw_1" onChange={handleChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
                <span>2. <input name="has_critknw_2" onChange={handleChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
                <span>3. <input name="has_critknw_3" onChange={handleChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
            </div>
        </div>

        <div className='has-container'>
            <div className='has-title'>Needs</div>
            <div>
                <span>1. <input name="need_critknw_1" onChange={handleNeedChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
                <span>2. <input name="need_critknw_2" onChange={handleNeedChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
                <span>3. <input name="need_critknw_3" onChange={handleNeedChange} id="namefield-tab" placeholder='Insert critcal Knowledge, Skills and Experience'/></span>
            </div>
        </div>

    </div>
  )
}

export default Criticalskills