import React, {useEffect, useState} from 'react'
import '../pages/css.css'
import Progressbar from '../Progressbar'

const Header = ({fired, headerData}) => {
    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fired])

    const [file, setFile] = useState(null)
    const [localfile, setLocalfile] = useState()
    const [hide, setHide] = useState(true)
    const [url, setUrl] = useState("")

    const [objcsk, setObjcsk] = useState({
        namesfield: "",
        rolefield: "",
        reporttofield: "",
        functionfield: "",
        ratingfield:"",
        imgurl: ""
    })

    const handleSubmit = () =>{
        const headerdata = {
            names: objcsk.namesfield,
            currentRole: objcsk.rolefield,
            reportingLine: objcsk.reporttofield,
            function: objcsk.functionfield,
            ImgUrl: url
        }
        headerData(headerdata)
    }

    const handleReturnUrl = (incomingurl) =>{
        console.log(`Obaloluwa: ${incomingurl}`)
        setUrl(incomingurl)
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setObjcsk({ ...objcsk, [name]: value });
    }

    const changeHandler = async(e) =>{
        let selected = e.target.files[0]
        console.log(selected)
        setFile(selected)
        setLocalfile(URL.createObjectURL(selected))
    }

    const hideText = () =>{
        setHide(false)
    }

  return (
    <div className='header-container'>
        <span id='main-heading'>Development Plan for  Talent</span>
        <div className='body-contain'>
            <div className='img-box'>
                <input type="file" accept='image/*' id="file" className='img-here' onChange={changeHandler} hidden/>
                <label for="file" id="submitButn" className='img-heree' onClick={hideText}>
                    {hide && <span>Upload Picture</span>}
                    <img alt=" " className='img-here-alt' src={localfile}/>
                </label> 
            </div>
            {file && <Progressbar file={file} setFile={setFile} returnUrl={handleReturnUrl}/>}
            <div className='img-box-right'>
                <span>
                    <label>Name:</label>
                    <input name="namesfield" onChange={handleChange} id="namefield" placeholder='Insert Name'/>
                </span>

                <span>
                    <label>Current Role:</label>
                    <input name="rolefield" onChange={handleChange} id="namefield" placeholder='Title'/>
                </span>

                <span>
                    <label>Report To:</label>
                    <input name="reporttofield" onChange={handleChange} id="namefield" placeholder='Manager Name'/>
                </span>

                <span>
                    <label>Division/Function:</label>
                    <input name="functionfield" onChange={handleChange} id="namefield" placeholder='Name'/>
                </span>

                <div className='ratings'>
                    <span name="ratingfield" id="level">Legend</span>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Header