import React, {useEffect} from 'react'
import useStorage from './Hooks/useStorage'
// import '../index.css'

const Progressbar = ({file, setFile, returnUrl}) => {
    const {url, progress} = useStorage(file)
    console.log(progress, url)

    useEffect(() => {
        if(url) {
          setFile(null);      //refresh file
          returnUrl(url)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])                 // if a url returns, that shows the upload is complete, then set File tp mull
  return (
    <div className="progress-bar" style={{width: progress + '%'}}></div>
  )
}

export default Progressbar